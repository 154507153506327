@import url('tailwind.css');

* {
  margin: 0;
  font-family: 'Avenir';
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

.tooltip-container {
  text-align: center;
  z-index: 1000;
  position: fixed;
  border-radius: 4px;
  padding: 8px 12px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: initial;
  color: #ffffff;
  width: auto;
  background: #05252d;
  box-sizing: border-box;
  opacity: 0;
  transform: translate(-50%, -30%);
  animation: tooltip-slide 0.18s ease-out 0.5s;
  animation-fill-mode: forwards;
  pointer-events: none;

  &::after {
    content: '';
    position: absolute;
    top: -6px;
    left: 20px;
    border-width: 6px 6px 0px 6px;
    border-style: solid;
    border-color: #05252d transparent;
    transform: rotate(180deg);
  }
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.placeholder {
  overflow: hidden;
  position: relative;
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background: linear-gradient(
    to right,
    #061e240d 2%,
    #061e241a 18%,
    #061e240d 33%
  );
  background-size: 500px;

  .content {
    width: 100%;
    position: absolute;
    z-index: 2;

    &:after,
    &:before {
      width: inherit;
      height: inherit;
      content: '';
      position: absolute;
    }
  }
}

@keyframes placeholderAnimate {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}
